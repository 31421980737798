<script lang="ts">
  import {
    type FeaturedExample,
    getExampleImage,
  } from "$lib/featured-examples";
  import ExampleCardLink from "./ExampleCardLink.svelte";
  import MarketingButton from "./MarketingButton.svelte";
  import Carousel from "./landing/Carousel.svelte";

  export let examples: FeaturedExample[];
  export let title: string;

  const cardWidth = 316;
  const cardGap = 10;
</script>

<Carousel {title} items={examples} {cardWidth} {cardGap}>
  <MarketingButton href="/docs/examples" slot="cta">View all</MarketingButton>
  {#each examples as example}
    <div class="shrink-0" style:width="{cardWidth}px">
      <ExampleCardLink
        href={`/docs/examples/${example.stem}`}
        title={example.title}
        description={example.description}
        image={getExampleImage(example)}
      />
    </div>
  {/each}
</Carousel>
